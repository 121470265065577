import React from 'react';
import "../css/style.css"
import "../css/flaticon.css"
import serviceImg from "../images/services.jpg"

const Service = () => {
    return (
        <>
            <div className="about-area fix">
                {/* left Contents  */}
                <div className="about-img" style={{ backgroundImage: `url(${serviceImg})` }}/>
                {/* Right Contents  */}
                <div className="about-details">
                    <div className="right-caption">
                        <div className="section-tittle mb-50">
                            <h1 className='text-skyblue'>Services</h1>
                        </div>
                        <div className="about-more">
                            <p className='text-white'>Our platform aspires to be the top choice for pet owners, delivering a complete
                                ecosystem for pet care, products, and services. We provide an exclusive
                                matching system to connect pets with the ideal care services and products.</p>
                        </div>
                    </div>
                </div>

            </div>
            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row mb-5 pb-5">
                        <div className="col-md-6 d-flex px-4 ">
                            <div className="d-block services text-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="flaticon-dog-eating"></span>
                                </div>
                                <div className="media-body p-4">
                                    <h3 className="heading">SERVICES</h3>
                                    <div style={{marginLeft:"20%"}}>
                                        <li className='left-align'>Exclusive Pet Matching System</li>
                                        <li className='left-align'>Complete Pet Care Services</li>
                                        <li className='left-align'>Tailored Recommendations</li>
                                        <li className='left-align'>Pet Owner Community</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-self-stretch px-4 ">
                            <div className="d-block services text-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="flaticon-blind"></span>
                                </div>
                                <div className="media-body p-4">
                                <h3 className="heading">Key Features</h3>
                                    <div style={{marginLeft:"20%"}}>
                                        <li className='left-align'>User Profiles and Pet Listings</li>
                                        <li className='left-align'>Advanced Search and Filter Options</li>
                                        <li className='left-align'>Review and Rating System</li>
                                        <li className='left-align'>Secure Payment Gateway and Transaction Management</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 d-flex align-self-stretch px-4">
                            <div className="d-block services text-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="flaticon-grooming"></span>
                                </div>
                                <div className="media-body p-4">
                                    <h3 className="heading">Pet Grooming</h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts. Separated
                                        they live in Bookmarksgrove right.
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="row mt-5 pt-4">
                        <div className="col-md-4 d-flex px-4 ">
                            <div className="d-block services text-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="flaticon-blind"></span>
                                </div>
                                <div className="media-body p-4">
                                    <h3 className="heading">Dog Walking</h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts. Separated
                                        they live in Bookmarksgrove right.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex px-4">
                            <div className="d-block services text-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="flaticon-dog-eating"></span>
                                </div>
                                <div className="media-body p-4">
                                    <h3 className="heading">Pet Daycare</h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts. Separated
                                        they live in Bookmarksgrove right.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex px-4 ">
                            <div className="d-block services text-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="flaticon-grooming"></span>
                                </div>
                                <div className="media-body p-4">
                                    <h3 className="heading">Pet Grooming</h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts. Separated
                                        they live in Bookmarksgrove right.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default Service;
