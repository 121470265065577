import { Link, useLocation } from 'react-router-dom';
import "../css/style.css"
import { useState } from 'react';

const NavigationBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { pathname } = useLocation();
    const handleNavLinkClick = () => {
        setIsOpen(!isOpen)
    };

    return (
        <nav className="header navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
            <div className="container-home">
                <Link className="navbar-brand" to="/" onClick={() => handleNavLinkClick()}>
                    <img src={"https://a3-public-bucket.s3.ap-south-1.amazonaws.com/SmartPetMart_Logo_WithoutText_Temporary.svg"} height={100} alt="logo" />
                    <span className="mr-2 text-skyblue">Smart</span>
                    <span className="mr-2">Pet Mart</span>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => { setIsOpen(!isOpen) }}
                    aria-controls="responsive-navbar-nav"
                    aria-expanded={isOpen ? 'true' : 'false'}
                    aria-label="Toggle navigation"
                >
                    <span className="fa fa-bars"></span> Menu
                </button>
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="responsive-navbar-nav">
                    <ul className="navbar-nav ml-auto">
                        <li className={`nav-item ${pathname === '/' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/" onClick={() => handleNavLinkClick()}>
                                Home
                            </Link>
                        </li>
                        <li className={`nav-item ${pathname === '/about' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/about" onClick={() => handleNavLinkClick()}>
                                About
                            </Link>
                        </li>
                        <li className={`nav-item ${pathname === '/services' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/services" onClick={() => handleNavLinkClick()}>
                                Services
                            </Link>
                        </li>
                        <li className={`nav-item ${pathname === '/ourJourney' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/ourJourney" onClick={() => handleNavLinkClick()}>
                                Our Journey
                            </Link>
                        </li>
                        <li className={`nav-item ${pathname === '/contact' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/contact" onClick={() => handleNavLinkClick()}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavigationBar;