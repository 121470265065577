import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import NavigationBar from './pages/Header';
import LoadingWrapper from './pages/LoadingWrapper';
import Home from './pages/Home';
import Footer from './pages/Footer';
import About from './pages/About';
import OurJourney from './pages/OurJourney';
import Service from './pages/Services';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondition from './pages/TermsandCondition';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0)
    }
  }, [pathname]);

  return (
    <>
      <LoadingWrapper />
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/ourJourney" element={<OurJourney />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-&-condition" element={<TermsAndCondition />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
