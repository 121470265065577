import React from 'react';
import "../css/style.css"

const PrivacyPolicy = () => {
    return (
        <>
            <section class="bg-light ftco-faqs padding-top-5 py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-body">
                                <h2 class="mb-3">Privacy Policy</h2>
                                <ul>
                                    <li>For all buyers, orders are shipped through registered domestic courier companies</li>
                                    <li>Orders are shipped within 8-14 days or as per the delivery date agreed at the time of order confirmation and delivery of the shipment subject to Courier Company / post office norms</li>
                                    <li>SMART PET MART is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 8-14 days from the date of the order and payment or as per the delivery date agreed at the time of order confirmation</li>
                                    <li>Delivery of all orders will be to the address provided by the buyer
                                    </li><li> Delivery of our services will be confirmed on your email address as specified during registration
                                    </li><li> For any issues with utilizing our services, you may contact our helpdesk at <a href="mailto:support@smartpetmart.com">support@smartpetmart.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default PrivacyPolicy;
