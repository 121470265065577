import React from 'react';
import "../css/style.css"
import ourJourneyimg from '../images/our-journey.jpg';

const OurJourney = () => {
    return (
        <>
            <section className="hero-wrap hero-wrap-2 position-relative" style={{ backgroundImage: `url(${ourJourneyimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="position-absolute text-overlay d-flex align-items-center justify-content-center w-100 h-100">
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-center">
                            <div className="col-md-12 text-center">
                                <h1 className="mb-0 bread">Welcome to Smart Pet Mart! -</h1>
                                <h3 className="text-white">The new way to find your fur-ever friend!</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bg-light ftco-faqs pt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-body">
                                <h2 class="mb-3">Our Journey</h2>
                                <p>We are two passionate pet lovers who have ventured into entrepreneurship to
                                    transform the pet industry with our groundbreaking online platform. Imagine a
                                    world where discovering the perfect pet is as simple as a swipe and accessing
                                    top-quality pet products is just a click away
                                    Our mission is to create a revolutionary platform that connects pet lovers with
                                    reputable breeders and trusted pet product suppliers.</p><p>
                                    What sets us apart? We’re dedicated to not only pets but also the people who
                                    love them. We believe every pet deserves a loving home, and every pet owner
                                    deserves a supportive community.</p><p>
                                    As pet parents ourselves, we understand the challenges of finding the right pet,
                                    sourcing quality products, and connecting with fellow enthusiasts. As a result,
                                    we embarked on a mission to transform the pet industry through technology
                                    and innovation.</p><p>
                                    Our vision is to enhance the joy of life with pets, driven by our commitment to
                                    innovation, customer satisfaction, and pet welfare. We are devoted to ensuring
                                    every pet finds a loving home and every owner has access to exceptional
                                    products and services.</p><p>
                                    We’re driven by our passion for animals and our determination to make a
                                    positive impact. We’re dedicated to building a platform that’s user-friendly,
                                    efficient, and enjoyable.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bg-light pt-5">
                <div class="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center mb-5">
                            <h2 className="heading-section">Founders</h2>
                        </div>
                    </div>
                    <div class="row align-item-center justify-content-center">
                        <div class="col-md-6 col-lg-3 ">
                            <div class="staff">
                                <div class="text pt-3 px-3 pb-4 text-center">
                                    <h3>Sammedh K Jain</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 ">
                            <div class="staff">
                                <div class="text pt-3 px-3 pb-4 text-center">
                                    <h3>Jaswanth Jain</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default OurJourney;
