import React, { useState } from 'react';
import "../css/style.css"
import aboutImg from '../images/about.jpg';

const About = () => {

  const [open, setOpen] = useState("collapseOne");

  const handleAccordin = (name) => {
    setOpen(name)
  }

  return (
    <>
      <div className="about-area fix">
        {/* Right Contents  */}
        <div className="about-details">
          <div className="right-caption">
            <div className="section-tittle mb-50 text-white">
              <h1 className='text-skyblue'>About Us</h1>
            </div>
            <div className="about-more text-white">
              <p> Our mission is to deliver a seamless and delightful shopping experience
                for pet lovers while supporting a diverse array of pet product sellers, breeders,
                and animal welfare organizations.</p>
            </div>
          </div>
        </div>
        {/* left Contents  */}
        <div className="about-img" style={{ backgroundImage: `url(${aboutImg})` }}/>
      </div>

      <section class="ftco-section bg-light ftco-faqs">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="heading-section mb-5 mt-5 mt-lg-0">
                <h2 class="mb-3">At Smart Pet Mart,</h2>
                <p> We're passionate about enhancing the joy of life with
                  pets. Our mission is to deliver a seamless and delightful shopping experience
                  for pet lovers while supporting a diverse array of pet product sellers, breeders,
                  and animal welfare organizations. We aim to build a vibrant community of
                  individuals who share our values of compassion, responsibility, and fun.</p>
                <p>
                  We aspire to be the premier destination for all pet-related needs, fostering a
                  culture of care and companionship.
                  Smart Pet Mart is dedicated to connecting pets with their families and providing
                  a top-notch shopping experience. With an extensive range of products and
                  services, we strive to meet and exceed all your pet care needs.</p>
              </div>
              <div id="accordion" class="myaccordion w-100" aria-multiselectable="true">
                <div class="card">
                  <div class="card-header p-0" id="headingOne">
                    <h2 class="mb-0">
                      <button href="#collapseOne"
                        class="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                        data-parent="#accordion" data-toggle="collapse" aria-expanded='true'
                        aria-controls="collapseOne">
                        <p class="mb-0">Our Team</p>
                        {/* <i class="fa" aria-hidden="true"></i> */}
                      </button>
                    </h2>
                  </div>
                  <div className={`collapse show`} id="collapseOne" role="tabpanel" aria-labelledby="headingOne">
                    <div class="card-body">
                      <p>Our team consists of dedicated professionals with extensive expertise in
                        animals and e-commerce. Combining years of experience in the pet industry
                        and online retail, we have united to develop a distinctive and user-friendly
                        platform that serves the needs of both pet enthusiasts and sellers.</p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header p-0" id="headingTwo" role="tab">
                    <h2 class="mb-0">
                      <button href="#collapseTwo"
                        class="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                        data-parent="#accordion" data-toggle="collapse" aria-expanded='true'
                        aria-controls="collapseTwo">
                        <p class="mb-0">Our Values</p>
                        {/* <i class="fa" aria-hidden="true"></i> */}
                      </button>
                    </h2>
                  </div>
                  <div className={`collapse show`} id="collapseTwo" role="tabpanel" aria-labelledby="headingTwo">
                    <div class="card-body py-3 px-0">
                      <ul>
                        <li><b>Convenience:</b> We believe shopping for pet products should be
                          effortless and enjoyable.</li>
                        <li><b>Community:</b> We are dedicated to fostering a supportive network of
                          pet lovers and sellers.</li>
                        <li><b>Compassion:</b> We are deeply committed to animal welfare and
                          advocate for responsible pet care.</li>
                        <li><b>Fun:</b> We believe that life with pets should be filled with happiness, and
                          we aim to bring a smile to your face with every purchase!</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header p-0" id="headingThree" role="tab">
                    <h2 class="mb-0">
                      <button href="#collapseThree"
                        class="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                        data-parent="#accordion" data-toggle="collapse" aria-expanded='true'
                        aria-controls="collapseThree">
                        <p class="mb-0">Our Goals</p>
                        {/* <i class="fa" aria-hidden="true"></i> */}
                      </button>
                    </h2>
                  </div>
                  <div className={`collapse show`} id="collapseThree" role="tabpanel" aria-labelledby="headingTwo">
                    <div class="card-body py-3 px-0">
                      <ul>
                        <li>Set a new benchmark in Online Pet Marketplaces</li>
                        <li>Build a thriving community of Pet Enthusiasts and Breeders</li>
                        <li>When she reached the first hills of the Italic Mountains</li>
                        <li>Create innovative solutions for Pet Care and Welfare</li>
                        <li>Enhance services to address evolving Customer Needs</li>
                        <li>Position SmartPetMart as a leader in Pet Industry Innovation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default About;
