import React, { useState } from 'react';
import homeImg from '../images/home1.jpg';
import home from "../images/home.jpg";
import "../css/style.css"
import "../css/flaticon.css"

const Home = () => {

  const [open, setOpen] = useState("collapseOne");

  const handleAccordin = (name) => {
    setOpen(name)
  }

  return (
    <>
      <div className="content" id="home">
        {/* <div className="home js-fullheight">
          <div className="position-absolute text-overlay d-flex w-100 h-100">
            <div className="container">
              <div className="row no-gutters slider-text align-items-start justify-content-left">
                <div className="col-md-12 text-center">
                  <h1 className="text-darkblue ">Welcome to Smart Pet Mart</h1>
                  <h2 className="text-skyblue">Your One-Stop Pet Solution</h2>
                  <h2 className="text-skyblue"> Where Pets and Pet Lovers Unite! </h2>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section className="hero-wrap js-fullheight position-relative" style={{ backgroundImage: `url(${home})`, backgroundSize: 'cover', backgroundPosition: 'center' }} data-stellar-background-ratio="0.5">
          <div className="position-absolute text-overlay d-flex w-100 h-100">
            <div className="container">
              <div className="row no-gutters slider-text align-items-start justify-content-left">
                <div className="col-md-12 text-center">
                  <h1 className="text-darkblue font-weight-700">Welcome to Smart Pet Mart</h1>
                  <h2 className="text-skyblue">Your One-Stop Pet Solution</h2>
                  <h2 className="text-skyblue"> Where Pets and Pet Lovers Unite! </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ftco-section bg-light ftco-no-pt ftco-intro">
          <div className="container">
            <div className="row">
              <div className="d-flex px-2">
                <div className="d-block services-home text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="flaticon-png-icon"></span>
                  </div>
                  <div className="media-body">
                    <p className='text-darkblue'>
                      Imagine a world where finding your perfect furry companion is just a click away.
                      At Smart Pet Mart, that world becomes reality!</p>
                    <p className='text-darkblue'>
                      We're excited to introduce you to SmartPetMart, a premier online platform
                      designed for pet lovers who want to pamper their pets and experience the
                      boundless joy of pet companionship. Our commitment is to provide a seamless,
                      enjoyable, and secure experience for all pet enthusiasts.</p>
                    <p className='text-darkblue'>
                      Our primary goal is to bring joy and companionship to pets and their families,
                      making us a unique and all-encompassing platform. We're dedicated to
                      ensuring every moment with your furry friend is special. Trust us to help you
                      provide your pet with a happy, healthy, and fulfilling life. After all, when pets are
                      happy, families are happy too.</p>
                    <p className='text-darkblue'>Smart Pet Mart stands out as a leading online destination that meets the
                      growing demand for a safe and convenient pet marketplace. With its innovative
                      features, competitive edge, and strategic approach, we are well-positioned for
                      success and growth in the pet industry. We’re here to make every moment with
                      your furry friend truly count.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-2">
          <div className="container">
            <div className="row d-flex ">
              <div className="col-md-5 d-flex">
                <div className="img img-video d-flex align-items-center justify-content-center justify-content-md-center"
                  style={{ backgroundImage: `url(${homeImg})` }}>
                </div>
              </div>
              <div className="col-md-7 mt-3 pl-md-5 py-md-5">
                <div className="row">
                  <div className="col-md-6 services-2 w-100 d-flex">
                    <div className="icon d-flex align-items-center justify-content-center"><span
                      className="flaticon-stethoscope"></span></div>
                    <div className="text pl-3">
                      <h4>Pet-Centric Approach</h4>
                      <p> Prioritizing pets in every decision we make</p>
                    </div>
                  </div>
                  <div className="col-md-6 services-2 w-100 d-flex">
                    <div className="icon d-flex align-items-center justify-content-center"><span
                      className="flaticon-customer-service"></span></div>
                    <div className="text pl-3">
                      <h4>Innovation-Driven</h4>
                      <p> Continuously exploring new ways to enhance our
                        services</p>
                    </div>
                  </div>
                  <div className="col-md-6 services-2 w-100 d-flex">
                    <div className="icon d-flex align-items-center justify-content-center"><span
                      className="flaticon-emergency-call"></span></div>
                    <div className="text pl-3">
                      <h4>Customer Commitment</h4>
                      <p> Devoted to providing outstanding
                        experiences</p>
                    </div>
                  </div>
                  <div className="col-md-6 services-2 w-100 d-flex">
                    <div className="icon d-flex align-items-center justify-content-center"><span
                      className="flaticon-veterinarian"></span></div>
                    <div className="text pl-3">
                      <h4>Breeder Support</h4>
                      <p>Empowering reputable breeders and ensuring the
                        well-being of their pets</p>
                    </div>
                  </div>
                  <div className="col-md-6 services-2 w-100 d-flex">
                    <div className="icon d-flex align-items-center justify-content-center"><span
                      className="flaticon-stethoscope"></span></div>
                    <div className="text pl-3">
                      <h4>Welfare Focused</h4>
                      <p> Committed to the health and happiness of every
                        pet</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
