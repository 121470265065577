import React, { useEffect, useState } from 'react';
import backgroundImage from '../images/contact.jpg';
import cotactImg from '../images/contact1.jpg';

const Contact = () => {
    const [subject, setSubject] = useState([])

    useEffect(()=>{
        setSubject([{ label: "Math", value: "math" }])
    },[])
    
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted!');
    };

    return (
        <>
            <section className="hero-wrap hero-wrap-2 position-relative" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="position-absolute text-overlay d-flex align-items-center justify-content-center w-100 h-100">
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-center">
                            <div className="col-md-12 text-center">
                                <h1 className="mb-0 bread">Contact</h1>
                                <h3 className="text-white">Feel free to reach out to us for any inquiries or questions you may have.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center mb-5">
                            <h2 className="heading-section">Contact</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row mb-5">
                                    <div className="col-md-6">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-map-marker"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Address:</span> #2768, Halladakeri,
                                                    Mahaveer Nagar,
                                                    Mysore - 570001</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-phone"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Phone:</span> <a href="tel://1234567920">+ 1235 2355 98</a></p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-paper-plane"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Email:</span> <a href="mailto:contactus@smartpetmart.in">contactus@smartpetmart.in</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-globe"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Website</span> <a href="#">yoursite.com</a></p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-7">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <h3 className="mb-4">Contact Us</h3>
                                            <form method="POST" id="contactForm" name="contactForm" className="contactForm">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">Full Name</label>
                                                            <input type="text" className="form-control" name="name" id="name" placeholder="Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="email">Email Address</label>
                                                            <input type="email" className="form-control" name="email" id="email" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">Subject</label>
                                                            <select className="form-control" name="subject" id="subject">
                                                                <option value="">Select a Subject</option>
                                                                {subject.map((data) => (<option value={data.value}>{data.label}</option>))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" for="#">Message</label>
                                                            <textarea name="message" className="form-control" id="message" cols="30"
                                                                rows="4" placeholder="Message"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input type="submit" value="Send Message" className="btn btn-primary" />
                                                            <div className="submitting"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-5 d-flex align-items-stretch">
                                        <div className="info-wrap w-100 p-5 img" style={{ backgroundImage: `url(${cotactImg})` }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62368.706275964905!2d76.61573982614753!3d12.312814272952016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf71b1331c1ddd%3A0x13d1d238c5fb079e!2sHalladaKeri%20Mahavir%20Nagar%20Mysore!5e0!3m2!1sen!2sin!4v1721571490430!5m2!1sen!2sin" width="600" height="450" style={{ border: 0, width: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    );
};

export default Contact;
