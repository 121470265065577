import { Link } from 'react-router-dom';
import "../css/style.css"

const Footer = () => {
    return (
        <footer className="footer footer_bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <h2 className="footer-heading">Smart Pet Mart</h2>
                        <p>Our revolutionary online platform is dedicated to bringing pets and their forever families together, providing a seamless, enjoyable, and safe experience for all pet enthusiasts.</p>
                        {/* <ul className="ftco-footer-social p-0">
                            <li ><a href="#" data-toggle="tooltip" data-placement="top" title="Twitter"><span className="fa fa-twitter"></span></a></li>
                            <li ><a href="#" data-toggle="tooltip" data-placement="top" title="Facebook"><span className="fa fa-facebook"></span></a></li>
                            <li ><a href="#" data-toggle="tooltip" data-placement="top" title="Instagram"><span className="fa fa-instagram"></span></a></li>
                        </ul> */}
                    </div>
                    <div className="col-md-6 col-lg-6 pl-lg-5 mb-4 mb-md-0">
                        <h2 className="footer-heading ml-lg-7">Quick Links</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-3 ">
                                <ul className="list-unstyled">
                                    <li><Link className="py-2 d-block" to="/" >
                                        Home
                                    </Link></li>
                                    <li> <Link className="py-2 d-block" to="/about" >
                                        About
                                    </Link></li>
                                    <li><Link className="py-2 d-block" to="/services" >
                                        Services
                                    </Link></li>
                                    <li><Link className="py-2 d-block" to="/ourJourney" >
                                        Our Journey
                                    </Link></li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <ul className="list-unstyled">
                                    <li><Link className="py-2 d-block" to="/contact">
                                        Contact
                                    </Link></li>
                                    <li><Link className="py-2 d-block" to="/privacy-policy" >
                                        Privacy policy
                                    </Link></li>
                                    <li><Link className="py-2 d-block" to="/terms-&-condition">
                                        Terms & Conditions
                                    </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <h2 className="footer-heading">Have a Questions?</h2>
                        <div className="block-23 mb-3">
                            <ul>
                                {/* <li><span className="icon fa fa-map"></span><span className="text">
                                    #2768, Halladakeri,
                                    Mahaveer Nagar,
                                    Mysore - 570001</span></li>
                                <li><a href="#"><span className="icon fa fa-phone"></span><span className="text">+2 392 3929 210</span></a></li> */}
                                <li><a  href="mailto:contactus@smartpetmart.in"><span className="icon fa fa-paper-plane"></span><span className="text">contactus@smartpetmart.in</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;